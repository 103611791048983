import Papa from 'papaparse';

const baseUrl = window.location.origin.includes('admin.genome.genpact.com')
  ? 'https://api.genpact.com/prod/genome/data-hose'
  : 'https://api.genpact.com/uat/genome/data-hose';

// import stringify from 'csv-stringify';
// import fs from 'fs';
// import VueJsonToCsv from 'vue-json-to-csv'
// import axios from 'axios';
// import { getEventListQuery } from '../queries';

// eslint-disable-next-line operator-linebreak

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

export default ({ $http }) => ({
  // revert events
  async revertHctSkill(context, data) {
    if (checkAccess(context, 10, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/revertEvent`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const hctData = await $http
      .request(config)
      .then((response) => {
        return response?.data?.body?.message;
      })
      .catch((error) => {
        console.log(error);
      });
    return hctData;
  },
  // archive events
  async archiveSkill(context, data) {
    if (checkAccess(context, 10, 'update')) return true;
    const config = {
      method: 'post',
      url: `${baseUrl}/statusUpdateEvent`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const archiveData = await $http
      .request(config)
      .then((response) => {
        return response?.data?.message;
      })
      .catch((error) => {
        console.log(error);
      });
    return archiveData;
  },
  // get event list
  async getEventList(context, data) {
    if (checkAccess(context, 10, 'read')) return true;
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/eventList`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };
    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setEventListInStore', response?.data || {});
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async downloadStatusReport(context, data) {
    if (checkAccess(context, 10, 'read')) return true;
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/downloadEventData`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };

    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data.data));
        const csvString = Papa.unparse(response.data.data);
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // Downlaod Capabilty dumb via mail
  async generateMail(context, data) {
    const config = {
      method: 'post',
      url: `${baseUrl}/downloadfile`,
      headers: {
        'content-type': 'application/json',
      },
      data,
    };
    const capabilityData = await $http
      .request(config)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return capabilityData;
  },

  async downloadCSV(context, data) {
    const csvName = JSON.parse(data);
    const config = {
      method: 'post',
      url: `${baseUrl}/downloadfile`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };

    await $http
      .request(config)
      .then((response) => {
        // console.log('response', JSON.stringify(response.data));
        // const csvString = Papa.unparse(response.data);
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${csvName.name}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // Downlaod HTML
  async downloadHtmL(context, data) {
    const csvName = JSON.parse(data);
    const config = {
      method: 'post',
      url: `${baseUrl}/downloadfile`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };

    await $http
      .request(config)
      .then((response) => {
        // console.log('response', JSON.stringify(response.data));
        // const csvString = Papa.unparse(response.data);
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${csvName.name}.Html`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // get event data
  async getEventData(context, data) {
    if (checkAccess(context, 10, 'read')) return true;
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/eventData`,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      data,
    };

    await $http
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        context.commit('setEventDataInStore', response?.data || {});
      })
      .catch((error) => {
        console.log(error);
      });
  },
});
