/* eslint-disable no-param-reassign */
import { Auth } from 'aws-amplify';

const checkAccess = (context, page, actionName) => {
  const accessJson = JSON.parse(localStorage.getItem('rbac_user'));
  const findObj = accessJson.pages.find((obj) => obj.code === page) || {};
  const userHasAccess = 'permission' in findObj && findObj.permission.some((objx) => objx === actionName);
  // alert(JSON.stringify(userHasAccess))
  if (!userHasAccess) {
    context.dispatch('addToastNotifications', {
      text: "You don't have permission to perform this action!",
      type: 'error',
      timer: 3000,
    });
    return true;
  }
  return false;
};

const getURL = (url) => {
  return window.location.origin.includes('admin.genome.genpact.com') ? `https://0e07e0d1xk.execute-api.ap-south-1.amazonaws.com/api/rbac${url}` : `https://655s39d7e4.execute-api.ap-south-1.amazonaws.com/api/rbac${url}`;
};

export default ({ $http }) => ({
  async setSession(context, payload) {
    $http.defaults.headers.common.Authorization = `Bearer ${payload.accessToken}`; // eslint-disable-line

    localStorage.setItem('accessToken', JSON.stringify(payload.accessToken));
    localStorage.setItem('user', JSON.stringify(payload.user));

    context.commit('setLoggedInState', {
      accessToken: payload.accessToken,
      user: payload.user,
    });
    return payload;
  },

  async getAmplifySession(context) {
    try {
      const res = await Auth.currentAuthenticatedUser();
      const email = (res.storage.user && JSON.parse(res.storage.user).email) || (res.attributes && res.attributes.email) || (res.signInUserSession.idToken.payload && res.signInUserSession.idToken.payload.email);
      const username = res.username.split('_').pop();
      const ohrId = username.split('@').shift() || '';
      const obj = {
        accessToken: res.signInUserSession.accessToken.jwtToken,
        user: { email, username, ohrId },
      };
      localStorage.setItem('accessToken', obj.accessToken);
      localStorage.setItem('user', JSON.stringify(obj.user));
      $http.defaults.headers.common.Authorization = `Bearer ${obj.accessToken}`;
      context.commit('updateSessionFromLocalDbInStore', obj);
      // context.dispatch('fetchUser');
      return obj;
    } catch (e) {
      console.warn(e);
      return context.dispatch('logout');
    }
  },

  async fetchUser(context) {
    return (
      $http
        // .get('/az/api/employee/v1/profile/ad', {
        .get('https://api-router.genpact.com/prod/api/employee/v1/profile/ad', {
          requestName: 'fetchUser',
        })
        .then(async (res) => {
          const user = res.data;
          const response = {
            firstName: user.givenName,
            lastName: user.surname,
            login: user.userPrincipalName,
            ohrId: user.ohrId,
            locale: 'en',
          };
          const currentUser = {
            ...JSON.parse(localStorage.getItem('user')),
            ...response,
          };
          localStorage.setItem('user', JSON.stringify(currentUser));
          context.commit('setUserInState', currentUser);
          return response;
        })
    );
  },

  async fetchUserRole(context) {
    const accessToken = localStorage.getItem('accessToken');
    const instance = $http.create({
      baseURL: getURL('/self'),
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const { data } = await instance
      .get('', {
        requestName: 'fetchUserRole',
      })
      .then(async (res) => {
        const user = res.data;
        const response = {
          firstName: user.firstName,
          lastName: user.lastName,
          ohrId: user.ohrid,
          locale: 'en',
        };
        const pages = user.pages.sort((a, b) => {
          if (a.code < b.code) return -1;
          if (a.code > b.code) return 1;
          return 0;
        });
        const responseForRbac = {
          ohrId: user.ohrid,
          pages,
          defaultRoute: pages[0].pageUrl,
          groups: user.groups,
        };
        const currentUser = {
          ...JSON.parse(localStorage.getItem('user')),
          ...response,
        };
        localStorage.setItem('user', JSON.stringify(currentUser));
        localStorage.setItem('rbac_user', JSON.stringify(responseForRbac));
        context.commit('setUserInState', currentUser);
        return response;
      });
    return data;
  },

  // access management query
  async getAllUsers(context) {
    if (checkAccess(context, 9, 'read')) return true;
    const accessToken = localStorage.getItem('accessToken');
    const instance = $http.create({
      baseURL: getURL('/users'),
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const { data } = await instance
      .get('', {
        requestName: 'fetchAllUsers',
      })
      .then(async (res) => {
        const users = res.data;
        context.commit('setAllUsersInState', users);
        return users;
      });
    return data;
  },

  // access management mutation
  async updateUser(context, variables) {
    if (checkAccess(context, 9, 'update')) return true;
    const accessToken = localStorage.getItem('accessToken');
    let config = {
      method: 'post',
      url: getURL('/users'),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(variables),
    };
    await $http
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response?.data?.message) {
          context.dispatch('addToastNotifications', {
            text: 'Request successfully executed!',
            type: 'success',
            timer: 3000,
          });
        }
        context.dispatch('getAllUsers');
      })
      .catch((error) => {
        console.log(error);
      });
    return true;
  },

  async logout(context, redirectUrl) {
    // eslint-disable-next-line no-param-reassign
    delete $http.defaults.headers.common.Authorization;
    await localStorage.clear();
    if (redirectUrl === '/signout') {
      context.commit('setNextRouteState', {
        next: '/signout',
      });
    } else {
      context.commit('setNextRouteState', {
        next: `/login${redirectUrl && typeof redirectUrl === 'string' ? `?redirect=${redirectUrl}` : ''}`,
      });
    }
    context.commit('setLoggedOutState');
  },
});
