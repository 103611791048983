<!-- eslint-disable max-len -->
<template>
  <main id="leftbar" style="width: 28rem" class="shadow-pane bg-white min-h-full fixed sm:relative z-10">
    <div class="flex flex-col text-sm my-4 text-gray-500">
      <div
        v-if="
          (userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 5)) ||
            userPayload.pages.some((obj) => obj.code === 6) ||
            userPayload.pages.some((obj) => obj.code === 7) ||
            userPayload.pages.some((obj) => obj.code === 10)
        "
        class="flex pl-4 py-2 cursor-pointer"
        :class="{ 'font-semibold text-blue-400': $route.name.includes('.Skills') || $route.name.includes('.Policies') || $route.name.includes('.EventList') || $route.name.includes('.Archetype') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />Skills Architecture Build (restricted)<InfoIcon
          @click="$emit('openReadme', { Type: 'Skill' })"
          class="instruction text-genome-dark-blue my-auto cursor-pointer ml-1"
          size="0.8x"
          title="Abbreviations"
        /><ChevronDownIcon @click="skillOpen" size="1.3x" class="mr-2 ml-2" />
      </div>
      <div v-if="skillShow" class="ml-4">
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 5)"
          :to="{ name: 'Dashboard.Skills' }"
          class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.Skills') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Define Function Role and Skills
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 6)"
          :to="{ name: 'Dashboard.Policies' }"
          class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.Policies') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Policies Role and Recommended Skills
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 7)"
          :to="{ name: 'Dashboard.Archetype' }"
          class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.Archetype') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Skill Archetypes
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 10)"
          :to="{ name: 'Dashboard.EventList' }"
          class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.Event') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Events HCT
        </router-link>
      </div>
      <div v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code >= 8 && obj.code < 9)" class="flex pl-4 py-2 cursor-pointer" :class="{ 'font-semibold text-blue-400': $route.name.includes('.grb') }">
        <FileTextIcon size="1.3x" class="mr-2" />Skills Architecture Master Data<InfoIcon
          @click="$emit('openReadme', { Type: 'Grb' })"
          class="instruction text-genome-dark-blue my-auto cursor-pointer ml-1"
          size="0.8x"
          title="Abbreviations"
        /><ChevronDownIcon @click="grbOpen" size="1.3x" class="mr-2 ml-2" />
      </div>
      <div v-if="grbShow" class="ml-4">
        <!-- <router-link
          :to="{ name: 'Dashboard.grb.ReadMeGRB' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.ReadMeGRB') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Read Me
        </router-link> -->
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 8.1)"
          :to="{ name: 'Dashboard.grb.SkillCategory' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.SkillCategory') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Skills Taxonomy Master
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 8.2)"
          :to="{ name: 'Dashboard.grb.SkillCapability' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.SkillCapability') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Skill Capability Framework
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 8.7)"
          :to="{ name: 'Dashboard.grb.SubSkillCapability' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.SubSkillCapability') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Sub Skill Capability Framework
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 8.3)"
          :to="{ name: 'Dashboard.grb.MGCertificate' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.MGCertificate') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          MG Guru Master
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 8.4)"
          :to="{ name: 'Dashboard.grb.SkillChannel' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.SkillChannel') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Skill Curriculum & Badges Master
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 8.8)"
          :to="{ name: 'Dashboard.grb.SubSkillChannel' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.SubSkillChannel') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Sub Skill Academy & Badges Master
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 8.5)"
          :to="{ name: 'Dashboard.grb.HistoryTrail' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.HistoryTrail') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Skills History Trail
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 8.6)"
          :to="{ name: 'Dashboard.grb.CriticalSkills' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.CriticalSkills') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Critical Skills
        </router-link>
      </div>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 11)"
        :to="{ name: 'Dashboard.JobFamilyGroup' }"
        class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
        :class="{ 'font-semibold text-blue-400': $route.name.includes('.JobFamilyGroup') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />
        Job Architecture Management<InfoIcon @click="$emit('openReadme', { Type: 'JA' })" class="instruction text-genome-dark-blue my-auto cursor-pointer ml-1" size="0.8x" title="Abbreviations" />
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 1)"
        :to="{ name: 'Dashboard.Download' }"
        class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
        :class="{ 'font-semibold text-blue-400': $route.name.includes('Download') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />
        Reports<InfoIcon @click="$emit('openReadme', { Type: 'reports' })" class="hidden instruction text-genome-dark-blue my-auto cursor-pointer ml-1" size="0.8x" title="Abbreviations" />
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 2)"
        :to="{ name: 'Dashboard.Upload' }"
        class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
        :class="{ 'font-semibold text-blue-400': $route.name.includes('Upload') }"
      >
        <UploadCloudIcon size="1.3x" class="mr-2" />
        Upload files<InfoIcon @click="$emit('openReadme', { Type: 'uploadfiles' })" class="hidden instruction text-genome-dark-blue my-auto cursor-pointer ml-1" size="0.8x" title="Abbreviations" />
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 3)"
        :to="{ name: 'Dashboard.Solidify' }"
        class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
        :class="{ 'font-semibold text-blue-400': $route.name.includes('Solidify') }"
      >
        <UploadCloudIcon size="1.3x" class="mr-2" />
        Solidify (Upload)<InfoIcon @click="$emit('openReadme', { Type: 'solidify' })" class="hidden instruction text-genome-dark-blue my-auto cursor-pointer ml-1" size="0.8x" title="Abbreviations" />
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 4)"
        :to="{ name: 'Dashboard.ReleaseManagement' }"
        class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
        :class="{ 'font-semibold text-blue-400': $route.name.includes('ReleaseManagement') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />
        Release Management
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 9)"
        :to="{ name: 'Dashboard.AccessManagement' }"
        class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
        :class="{ 'font-semibold text-blue-400': $route.name.includes('.AccessManagement') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />
        Access Management<InfoIcon @click="$emit('openReadme', { Type: 'accesssMgmt' })" class="instruction text-genome-dark-blue my-auto cursor-pointer ml-1" size="0.8x" title="Abbreviations" />
      </router-link>
      <div
        @click="linShow = !linShow"
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code >= 13 && obj.code < 14)"
        class="flex pl-4 py-2 cursor-pointer"
        :class="{ 'font-semibold text-blue-400': $route.name.includes('.LinkedIn') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />LinkedIn Learning
        <!-- <InfoIcon
          class="instruction text-genome-dark-blue my-auto cursor-pointer ml-1"
          size="0.8x"
          title="Abbreviations"
        /> -->
        <ChevronDownIcon size="1.3x" class="mr-2 ml-2" />
      </div>
      <div v-if="linShow" class="ml-4">
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 13)"
          :to="{ name: 'Dashboard.LinkedIn.ContentSearch' }"
          class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.LinkedIn.ContentSearch') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Content Search
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 13.1)"
          :to="{ name: 'Dashboard.LinkedIn.LearningActivities' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.LinkedIn.LearningActivities') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Learning Activities
        </router-link>
        <router-link
          v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 13.2)"
          :to="{ name: 'Dashboard.LinkedIn.JobExecutionHistory' }"
          class="hover:bg-gray-100 flex items-center px-4 py-2 transition-colors"
          :class="{ 'font-semibold text-blue-400': $route.name.includes('.LinkedIn.JobExecutionHistory') }"
        >
          <FileTextIcon size="1.3x" class="mr-2" />
          Job Execution History
        </router-link>
      </div>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 14)"
        :to="{ name: 'Dashboard.PersonaManagement' }"
        class="hover:bg-gray-100 flex items-center pl-4 py-2 transition-colors"
        :class="{ 'font-semibold text-blue-400': $route.name.includes('.PersonaManagement') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />
        Persona Management
      </router-link>
    </div>
  </main>
</template>

<script>
import { UploadCloudIcon, FileTextIcon, InfoIcon, ChevronDownIcon /* , DownloadCloudIcon, SlidersIcon */ } from 'vue-feather-icons';

export default {
  name: 'TheSidebar',
  props: {
    navExpand: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadCloudIcon,
    FileTextIcon,
    ChevronDownIcon,
    InfoIcon,
    // SlidersIcon,
    // DownloadCloudIcon,
  },
  data() {
    return {
      linShow: false,
      grbShow: false,
      skillShow: false,
      userPayload: JSON.parse(localStorage.getItem('rbac_user')),
    };
  },
  methods: {
    skillOpen() {
      if (this.skillShow) {
        this.skillShow = false;
      } else this.skillShow = true;
    },
    grbOpen() {
      if (this.grbShow) {
        this.grbShow = false;
      } else this.grbShow = true;
    },
  },
};
</script>

<style scoped>
.shadow-pane {
  box-shadow: 0 3px 15px #41597117;
}

.slide-enter-active,
.slide-leave-active {
  transition: width 0.2s linear;
}

.slide-enter-from,
.slide-leave-to {
  width: 0;
}
</style>
