import Vue from 'vue';
import Vuex from 'vuex';

import accountsModule from './accounts/accounts-store';
import notificationsModule from './notifications-store';
import requestModule from './requests-store';
import routerModule from './router-store';
import queriesModule from './queries/queries-store';
import skillsModule from './skills/skills-store';
import policiesModule from './policies/policy-store';
import skillPoliciesModule from './skillPolicies/skillPolicy-store';
import eventModule from './event/event-store';
import archetypeModule from './archetype/archetype-store';
import skillCatogoryModule from './skillCategory/skillCategory-store';
import skillCapabilityModule from './skillCapability/skillCapability-store';
import subSkillCapabilityModule from './subSkillCapability/subSkillCapability-store';
import skillChannelModule from './skillChannel/skillChannel-store';
import policiesSkillModule from './policiesSkill/policySkill-store';
import mgCertificateActionModule from './mgCertificate/mgCertificate-store';
import jobArchitectureActionModule from  './jobArchitecture/jobArchitecture-store';
import historyTrailActionModule from './historyTrail/historyTrail-store';
import linkedInActionModule from './linkedIn/linkedIn-store';
import criticalSkillsActionModule from './criticalSkills/criticalSkill-store';
import personaActionModule from './personas/persona-store';
// import ruleEngineModule from './rule-engine/rule-engine-store';

Vue.use(Vuex);

const injectionObj = {
  $http: Vue.prototype.$http,
};

export default new Vuex.Store({
  strict: true,
  modules: {
    accounts: accountsModule(injectionObj),
    notifications: notificationsModule(),
    request: requestModule(),
    router: routerModule(),
    queries: queriesModule(injectionObj),
    skills: skillsModule(injectionObj),
    policies: policiesModule(injectionObj),
    skillPolicies: skillPoliciesModule(injectionObj),
    event: eventModule(injectionObj),
    archetype: archetypeModule(injectionObj),
    policiesSkill: policiesSkillModule(injectionObj),
    skillCatogory: skillCatogoryModule(injectionObj),
    skillCapability: skillCapabilityModule(injectionObj),
    subSkillCapabilty: subSkillCapabilityModule(injectionObj),
    skillChannel: skillChannelModule(injectionObj),
    mgCertificate: mgCertificateActionModule(injectionObj),
    jobAcrhitecture: jobArchitectureActionModule(injectionObj),
    historyTrail: historyTrailActionModule(injectionObj),
    linkedIn: linkedInActionModule(injectionObj),
    criticalSkills: criticalSkillsActionModule(injectionObj),
    personas: personaActionModule(injectionObj),
    // ruleEngine: ruleEngineModule(injectionObj),
  },
});
