<template>
  <div>
    <div class="p-4">
      <h1 class="p-4 border-b text-xl font-semibold text-genome-dark-blue">
        {{ skillName || skillType }}
      </h1>
      <!-- eslint-disable-next-line vue/no-parsing-error -->
      <div class="relative p-4 w-full flex">
        <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">
          {{ label }}
        </p>
        <vue-editor id="quill-container" v-model="description" :editor-toolbar="customToolbar" class="focus:outline-none text-sm leading-tight text-gray-700 bg-gray-50 rounded w-full h-full" :placeholder="`${placeValue}`" />
      </div>
    </div>
    <div class="relative p-4 w-full flex justify-end space-x-4">
      <button @click="$emit('clearDescription', description)" class="button px-4 py-1 rounded text-sm bg-white text-blue-800 border">
        Cancel
      </button>
      <button @click="$emit('addDescription', description, type)" :class="{ 'bg-genome-dark-blue border-genome-dark-blue': skillName.length }" class="button px-4 py-1 rounded text-sm bg-genome-dark-blue text-white border border-blue-300">
        Update
      </button>
    </div>
    <div v-if="label.includes('Skill Description') || label.includes('Usecase Description')" class="">
      <div v-if="label.includes('Skill Description')" class="relative px-4 w-full flex justify-start">
        <button type="button" @click="copyToClipboard" class="button px-4 py-1 rounded text-sm bg-genome-dark-blue text-white border border-blue-300">
          copy
        </button>
      </div>
      <span v-else-if="label.includes('Usecase Description')" class="relative px-4 w-full flex justify-start underline"> Preview: </span>
      <div id="copySPText" class="v-html-comp m-4 p-4 border" v-html="description" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'EditorComponent',
  props: {
    skillName: {
      type: String,
      default: '',
    },
    skillDescription: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    skillType: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      description: '',
      descriptionSkill: this.skillDescription,
      customToolbar: [
        // [{ 'font': [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ header: 1 }, { header: 2 }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }],
        [{ color: ['#ooo', '#fff', '#2A588D', '#2A588D', '#3CC8EE', '#707070', '#7E7E7E', '#5572a0', '#ff555f', '#00AECF', '#ff545e', '#083361', '#00adcf'] }, { background: [] }],
        ['link', 'image', 'video', 'formula'],
        [{ direction: 'rtl' }],
        ['clean'],
      ],
    };
  },
  watch: {
    skillDescription(val) {
      this.description = val;
    },
  },
  methods: {
    copyToClipboard() {
      const textToCopy = document.getElementById('copySPText');

      // Create a range to select the text
      const range = document.createRange();
      range.selectNode(textToCopy);

      // Select the text
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Copy the selected text
      document.execCommand('copy');

      this.$store.dispatch('addToastNotifications', {
        text: 'Text copied to clipboard!',
        type: 'success',
        timer: 3000,
      });

      // Deselect the text
      selection.removeAllRanges();
    },
  },
  // mounted() {
  //   this.descriptionSkill = this.skillDescription;
  // },
  // watch: {
  //   // whenever question changes, this function will run
  //   descriptionSkill() {
  //     this.$parent['edit-skill-popup'].$data.isOpen = false;
  //   this.descriptionSkill = this.skillDescription;
  //   console.log('descriptionSkill', this.descriptionSkill);
  //   }
  // },
};
</script>
<style scoped>
@layer components {
  .v-html-comp :deep(ul) {
    list-style: disc !important;
    margin-left: 3.5rem !important;
    padding: 0;
  }
  .v-html-comp :deep(ol) {
    list-style: auto !important;
    margin-left: 3.5rem !important;
    padding: 0;
  }
  .v-html-comp :deep(.ql-indent-2) {
    margin-left: 3rem;
  }
  .v-html-comp :deep(.ql-indent-1) {
    margin-left: 3rem;
  }
}
@tailwind components;
</style>
