import { render, staticRenderFns } from "./ring-loader.vue?vue&type=template&id=69123107&scoped=true&"
import script from "./ring-loader.vue?vue&type=script&lang=js&"
export * from "./ring-loader.vue?vue&type=script&lang=js&"
import style0 from "./ring-loader.vue?vue&type=style&index=0&id=69123107&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69123107",
  null
  
)

export default component.exports